<template>
  <div class="am-trade-in" v-if="autouncleTradeInIntegration">
    <div class="am-trade-in__inner">
      <amui-compact-toolbar
        v-if="appMode === 'widget'"
        class="am-trade-in__compact-toolbar"
      >
        <amui-compact-link
          v-if="favoriteVehiclesCount"
          label="Favoriten"
          icon="favorite"
          :badge="favoriteVehiclesCount"
          @click.native="onClickFavorites"
        />
        <amui-compact-link
          label="Alle Fahrzeuge"
          icon="tune-simple"
          @click.native="onClickSearch"
        />
      </amui-compact-toolbar>

      <div class="amui-mt-24 amui-pb-16 amui-pb-24:m amui-pb-32:xl">
        <amui-headline
          v-if="cmsHeadline"
          :text="cmsHeadline"
          html-tag="h1"
          looks-like="h5"
        />
        <div class="am-trade-in__content-split">
          <div v-if="cmsText">
            {{ cmsText }}
          </div>
          <amui-checklist v-if="cmsChecklist" :list="cmsChecklist" />
        </div>

        <div
          class="amui-flex amui-justify-end amui-text-right amui-mt-32 amui-mt-40:m amui-mt-48:xl"
        >
          <div class="au-trade-in-backlink">
            <div class="amui-mb-4 amui-font-body-2 amui-color-grey-2">
              Preisüberprüfung in Zusammenarbeit mit
            </div>
            <a href="https://www.autouncle.de/" target="_blank">
              <img
                style="height: 16px;"
                src="https://www.autouncle.de/widgets/au_backlink_logo"
                alt="AutoUncle"
              />
            </a>
          </div>
        </div>
      </div>

      <div>
        <div id="au-trade-in-calculator">
          <template v-if="hasAutouncleConsent">
            <iframe
              v-if="showIframe"
              :src="widgetUrl"
              :style="{
                width: '100%',
                height: '100vh',
                minHeight: '1px',
                minWidth: '1px',
                border: 'none'
              }"
              title="Autouncle Ankaufformular"
            ></iframe>
          </template>
          <template v-else>
            Der Service "Autouncle Aps" wurde über den Cookie Banner bislang
            nicht akzeptiert. Ändere die Einstellungen der Cookies und erlaube
            den Service "Autounce Aps" um das Formular für den Fahrzeugankauf
            angezeigt zu bekommen.
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import SeoMixin from './mixins/seo.mixin'
import { AmuiHeadline } from '@/../ui/components/headline'
import { AmuiChecklist } from '@/../ui/components/checklist'
import { AmuiCompactToolbar } from '@/../ui/components/compact-toolbar'
import { AmuiCompactLink } from '@/../ui/components/compact-link'

export default {
  name: 'TradeIn',

  components: {
    AmuiHeadline,
    AmuiChecklist,
    AmuiCompactToolbar,
    AmuiCompactLink
  },

  mixins: [SeoMixin],

  metaInfo() {
    return this
      ? {
          title: this.seoTitle,
          titleTemplate: '%s',
          meta: [{ name: 'description', content: this.seoDescription }]
        }
      : null
  },

  data() {
    return {
      showIframe: false
    }
  },

  computed: {
    ...mapState('core', ['locale', 'appMode']),
    ...mapGetters('core', ['companyId', 'getIntegrationByName']),
    ...mapGetters('consent', { consentAllowedServices: 'allowedServices' }),
    ...mapState('favorites', {
      favoriteVehiclesCount: state => state.vehicles.length
    }),
    autouncleTradeInIntegration() {
      return this.getIntegrationByName('autouncle-trade-in')
    },
    hasAutouncleConsent() {
      return this.consentAllowedServices.includes('autouncle')
    },
    cmsHeadline() {
      let content = this.getLocalizedContent('headline')
      return content ? content.text : null
    },
    cmsText() {
      let content = this.getLocalizedContent('text')
      return content ? content.text : null
    },
    cmsChecklist() {
      let content = this.getLocalizedContent('textlist')
      return content ? content.text : null
    },
    widgetUrl() {
      let url =
        'https://www.autouncle.de/widgets/trade_in_valuations/new?' +
        this.widgetParams
      return url
    },
    widgetParams: function() {
      const params = new URLSearchParams()
      params.append('c_id', this.autouncleTradeInIntegration.settings.id)
      params.append('hide_au_logo', '1')
      params.append(
        'data_from_source',
        JSON.stringify({
          integrationType: 'inline-form',
          customData: {
            leadSource: 'Ankauf Lead'
          }
        })
      )
      params.append(
        'd',
        JSON.stringify({
          device: window.matchMedia('only screen and (max-width: 760px)')
            .matches
            ? 'mobile'
            : 'desktop',
          url: document.location.href
        })
      )
      params.append('car_url', document.location.href)
      params.append('parent_id', 'au-trade-in-calculator')
      params.append('initialWidth', '100%')
      params.append('childId', 'au-trade-in-calculator')
      return params.toString()
    }
  },
  mounted() {
    this.showIframe = true
  },
  methods: {
    onClickFavorites() {
      this.$router.push({ name: 'favorites' })
    },
    onClickSearch() {
      this.$router.push({ name: 'app' })
    },
    getLocalizedContent(key) {
      let content

      if (
        this.autouncleTradeInIntegration.settings.content &&
        this.autouncleTradeInIntegration.settings.content[key]
      ) {
        content = this.autouncleTradeInIntegration.settings.content[key].find(
          d => d.lang === this.locale
        )
      }

      return content
    }
  }
}
</script>
