<template>
  <div
    class="amui-checklist"
    :class="{ 'amui-checklist--2-columns': this.type === 'two-columns' }"
  >
    <div v-if="headline" class="amui-checklist__headline">
      {{ headline }}
    </div>
    <ul>
      <li v-for="item in list" :key="item">
        <amui-icon name="check" class="amui-checklist__item-icon" size="sm" />
        <div class="amui-checklist__item-title">
          {{ item }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { AmuiIcon } from './../icon'

export default {
  name: 'AmuiChecklist',

  components: {
    AmuiIcon
  },

  props: {
    headline: {
      type: String,
      required: false,
      default: null
    },
    list: {
      type: Array,
      required: false,
      default: () => []
    },
    type: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return ['two-columns'].includes(value)
      }
    }
  }
}
</script>
